import React, { useEffect, useRef, useState } from 'react'
import Tobnavbar from '../Components/Top/Tobnavbar';
import * as CONSTANTS from "./../CONSTANTS";
import axios from "axios";
import { addItem, removeItem } from './../reduxAuth/cartSlice';
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import {incrementQuantity, incrementProductQuantity} from './../reduxAuth/cartSlice';
import { FaBatteryEmpty } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function Shop() {
  
    const cartItems                                             = useSelector(state => state.cart.items);

    const [processing, setProcessing]                           = useState(false);
    const [totalQuantity, setTotalQuantity]                     = useState(0);
    const [productList, setProductList]                         = useState();    
    const [prodTotal, setProdTotal]                             = useState(0)

    const [searchText, setSearchText]                           = useState();

    const [currentCatalogue, setCurrentCatalogue]               = useState();  
    const [pageNumber, setPageNumber]                           = useState(1);
    const [itemsLimit, setItemsLimit]                           = useState(36);
    const [recordsMessage, setRecordsMessage]                   = useState('');
    const [searchStatus, setSearchStatus]                       = useState(false);
    const [transferedSearch, setTransferedSearch]               = useState(false);

    const inputSearchRef                                        = useRef();
    const dispatch                                              = useDispatch();
    const location                                              = useLocation();


    useEffect(() => {
      getCatalogueData();
        checkProductCount();
        const hiddenString = location.state && location.state.searcher;

        if(hiddenString){
          //call the search
          setSearchText(hiddenString);
          setTransferedSearch(true);          
        }else {
          getInitialProductList();
        }
       
    },[]);

    useEffect(() => {
      if(pageNumber > 1){
        getAppendProductList();
      }
      
    },[pageNumber]);
    
    useEffect(() => {
      if(transferedSearch){
        if(currentCatalogue){
          searchForTheListOfProducts();
        }
        
      }
    },[transferedSearch, currentCatalogue]);

    const handleAddToCart = (picture) => {
      const existingItem = cartItems.find(item => item._id === picture._id);

      if (existingItem) {
        // If item already exists, increment quantity
        dispatch(incrementQuantity(existingItem._id)); // Increment quantity in cart
        dispatch(incrementProductQuantity(existingItem._id)); // Increment quantity in product
      } else {
        // If item doesn't exist, add it with quantity 1
        dispatch(addItem(picture));
        dispatch(incrementProductQuantity(picture._id)); // Increment quantity in product
      }

       // Update total quantity
       const newTotalQuantity = cartItems.reduce((total, item) => total + item.quantity, 1);
      setTotalQuantity(newTotalQuantity);
    };

    const getInitialProductList = async () => {

        try{
          try{
            setProcessing(true);           
  
            const paginator = {
              "page" : pageNumber,
              "limit" :itemsLimit
            }
  
            const res = await axios.put(CONSTANTS.API_PICNIC + "catalogues/categorized/products/sum/v2", paginator, {
              headers: {
                api_key: CONSTANTS.TOKEN_CATALOGUE
              }
            });
      
           /// console.log(res);
            setProductList(res.data);
            setProdTotal(res.data.length);
            setProcessing(false);
           
          }catch(err){
            console.log("WEB ERR");
            console.log(err);
            setProcessing(false);
          }

        }catch(err){
            console.log(err);
        }
    }   

    const getAppendProductList = async () => {

      try{
        try{
          setProcessing(true);           

          const paginator = {
            "page" : pageNumber,
            "limit" :itemsLimit
          }

          const res = await axios.put(CONSTANTS.API_PICNIC + "catalogues/categorized/products/sum/v2", paginator, {
            headers: {
              api_key: CONSTANTS.TOKEN_CATALOGUE
            }
          });
    
          //console.log(res);
          if(res.data.length > 0){
            setProductList(prevProductList => [...prevProductList, ...res.data]);
            setProdTotal(preTotal => preTotal + itemsLimit);
          }else {
              setRecordsMessage("No further records");
          }
          setProcessing(false);
          
        }catch(err){
          console.log("Issue Err");
          console.log(err);
          setProcessing(false);
        }

      }catch(err){
          console.log(err);
      }
    }


    const checkProductCount = () => {
      const cartData = localStorage.getItem(CONSTANTS.CART_COOKIE);
      if(cartData){
        const savedCartData = JSON.parse(cartData);

        let totalQuantity = 0;
        savedCartData.forEach(item => {
          totalQuantity += item.quantity;
        });

       // console.log(totalQuantity);
        setTotalQuantity(totalQuantity);
      }
    }

    const getCatalogueData = async () => {

      try{

          const resCata = await axios.get(CONSTANTS.API_PICNIC + "catalogues/list", {
              headers: {
                api_key: CONSTANTS.TOKEN_CATALOGUE
              }
            });

            //console.log(resCata);
            setCurrentCatalogue(resCata.data);

        }catch(err){
            console.log(err);
        }
    }   
    
    const handleSearchTextChange = (e) => {
      
        setSearchText(e.target.value)
    }

    const clearCurrentList = () => {
      setSearchText("");
      setPageNumber(1);
      setItemsLimit(36);
      setSearchStatus(false);
      setTransferedSearch(false); 
      getInitialProductList();
      inputSearchRef.current.value ="";
    }

    const incrementPage = () => {

      if(!searchStatus){
        setPageNumber(prevPageNumber => prevPageNumber + 1);
      }      
    }

    const searchForTheListOfProducts = async () => {
        try{
          setProcessing(true);

          const paginateSearch = JSON.stringify({
            "searchText": searchText,
            "catalogueId": currentCatalogue._id
          });

          let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: CONSTANTS.API_PICNIC + 'catalogues/categorized/search/data',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : paginateSearch
          };
          //Clear the data
          setProductList([]);
          setProdTotal(0);
          const res = await axios.request(config);

          
          setProductList(res.data);
          setProdTotal(res.data.length);
          setSearchStatus(true);  
          /*      
          setPageNumber(1);
          setItemsLimit(36);
          */
          setProcessing(false);
        }catch(err){
          console.log("Come On Err");
          console.log(err);
          setProcessing(false);
        }
      }
  return (    
    <div>
        <Tobnavbar
            currentSearched={searchText}
            prodTotal={prodTotal}
            totalQuantity={totalQuantity}
            />

            <div className="shop-box-container">
                <div className="mgtop80">
                  {
                    processing && (
                        <div className="process-container">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="processing"/>
                        </div>
                    )
                  }

                  <h4 className="page-title-base">Shop</h4>
                  <div className="row-search">
                    <input type="text" className="form-control searchingfield" placeholder="Enter the search item" onChange={handleSearchTextChange} ref={inputSearchRef} />
                    {
                      currentCatalogue ? (
                       <>
                          {
                            !processing && (
                              <button className="btn btn-cjdsa" onClick={() => searchForTheListOfProducts()}> 
                                <span className="icBtn"><FaSearch /></span>
                              </button>
                            )
                          }
                      
                       </>
                      )
                      :
                      <img src={CONSTANTS.SMALL_PROCESSINGV2} className="button-loading" />
                    }
                    
                    
                  </div>
                </div>
                <div className="product-container-list">
                  <div className="clear-prow pd10">
                    {
                      searchStatus && (<button className="btn btn-cjdsa" onClick={() => clearCurrentList()}><FaBatteryEmpty /> <span className="pd10">CLEAR</span></button>)
                    }
                    
                  </div>
                   {
                    productList && (
                      <div className="row">
                          {
                            productList.map((picture, index) => {
                              return (<div className="col-md-3 col-sm-6" key={index}>
                                        <div className="frame-product">
                                            <div className="barcode-display1">{picture.barcode}</div>
                                            <div className="current-image">
                                                <img src={picture.imgurl} className="image-product-1" />
                                            </div>
                                            <div className="content-txt">
                                                <div className="title-text">
                                                  {picture.title}
                                                </div>
                                                <div className="sub-descrpt">
                                                  {picture.onlineDesc.length > 136 ?
                                                     <span>{picture.onlineDesc.substr(0, 133)}...</span>
                                                     :
                                                      <span>{picture.onlineDes}</span>
                                                  }
                                                </div>
                                                <div className="flexme">
                                                  <div className="flex50">
                                                      <div className="pricing-select">
                                                          {picture.price}
                                                      </div>
                                                  </div>
                                                  <div className="flex50">
                                                    <div className="text-center">
                                                    <button className="btn btn-cjdsa btncart" onClick={() => handleAddToCart(picture)}>Add to Cart</button>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>       
                                      </div>)
                            })
                          }
                      </div>
                    )
                   }

                   {
                    searchStatus ?
                    <div className="row">
                          
                      </div>
                    :
                    <div className="row">
                        <div className="col-md-4 small-text">
                            <span className="bottom-txt">Page</span> 
                            <span className="bt-txt">{pageNumber}</span>
                        </div>
                          {
                            productList && (
                              <div className="col-md-4 message-button">
                                  {
                                      recordsMessage.length > 0 ?
                                      <div className="alert alert-warning">{recordsMessage}</div>
                                      :
                                        <button className="btn btn-cjdsa" onClick={() => incrementPage()}>Load More</button>
                                    }
                                  
                              </div>
                            )
                          }
                        
                    </div>
                   }
                
                </div>
            </div>
    </div>
  )
}

export default Shop