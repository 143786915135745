import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaSearch, FaTrash } from 'react-icons/fa';
import {incrementQuantity, decrementQuantity, removeItem, clearCart} from './../reduxAuth/cartSlice';
import * as CONSTANTS from "./../CONSTANTS";
import axios from 'axios';

function ShoppingCart() {
    const dispatch                                            = useDispatch();
    const cartItems                                           = useSelector(state => state.cart.items);
    const {user}                                              = useSelector((state) => state.auth);
    const [cartOrderMessage, setCartOrderMessage]             = useState();
    const [classOrderMessage, setClassOrderMessage]           = useState();

    const [processing, setProcessing]                         = useState(false);

    const [paymentCost, setPaymentCost]               = useState(0.00);

    const handleIncrement = (productId) => {
        dispatch(incrementQuantity(productId));    
    };

    const handleDecrement = (productId) => {
       dispatch(decrementQuantity(productId));
    };
    
    const handleRemove = (productId) => {
        dispatch(removeItem(productId))
    }

    useEffect(() => {
        calcualateTheTotalCost();
    },[cartItems])

    const calcualateTheTotalCost = () => {
     
        let amountTotal = 0.00;
        cartItems.forEach(item => {
            if(item.product.price.length > 0){
                const newAmount = parseFloat(item.product.price) * item.quantity;
                amountTotal  = amountTotal + newAmount;
            }
        
        });

        setPaymentCost(amountTotal)
    }

    const handleExecuteOrderingNow = async () => {
        try{
            /*
            console.log(user);
            console.log(cartItems);

            console.log("(((((((((((((((((0))))))))))))))))");
            */
            setProcessing(true);

            const cartLength    = cartItems.length;
            let newProductList = [];
            let newMongoProductList = [];
            /////////////////////////////////////////////@********** */
                
            const orderCode = makeid(3) + "" + Math.floor(Math.random() * 900) + 100;
            for (let i = 0; i < cartLength; i++) {

                const newItem = {
                    "cjdAccountNumber": user.cjdAccountNumber,
                    "ordercode": orderCode,
                    "orderstatus":"Initial",
                    "barcode" : cartItems[i].product.barcode,
                    "title" : cartItems[i].product.title,
                    "onlineDesc" : cartItems[i].product.onlineDesc + "",
                    "deliveryAddress": user.deliveryAddress,
                    "img_url" : cartItems[i].product.imgurl,
                    "price" : cartItems[i].product.price,
                    "qty" : cartItems[i].quantity
                }

                newMongoProductList.push(newItem); 
             } 
            /////////////////////////////////////////////@********** */
            //const orderObject = JSON.stringify(newMongoProductList);
            //console.log(CONSTANTS.API_URL + 'orders/create/v1');
            //console.log(orderObject);
            const resOrders = await axios.post(CONSTANTS.API_URL + 'orders/create/v1', newMongoProductList);

            ////////////////////////////////////////////********** */

            if(resOrders.status == 200){
                setCartOrderMessage("Your order was successfull.");
                setClassOrderMessage("alert-success");
                dispatch(clearCart());
            }else {
                setCartOrderMessage("Something went wrong with the order, please try again later.");
                setClassOrderMessage("alert-danger");
            }
            

            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

  return (
    <div className="shop-cart-container">
         <div className="openingbanner">
            <div className="container text-center">
                <Link to="/shop" className="btn btn-cjdsa lg-butt1 mgtop30">
                    <span className="iconSearch"><FaSearch /></span>
                    <span className="txtSearchButt"> SEARCH FOR PRODUCTS</span>
                </Link>
            </div>
         </div>
           <div className="container mgtop30">
           {
                processing && (
                    <div className="process-container">
                        <img src={CONSTANTS.SMALL_PROCESSING} className="processing"/>
                    </div>
                )
            }
   
            <h4 className="page-title-base">Shopping Cart</h4>
               <div className="row rsminus">
                  <div className="col-md-7">
                    <div className="cart-list-container">
                            {
                            cartItems.length > 0 ?
                            <div className="cart-listing">
                                        {
                                            cartItems.map(item => (
                                            <div className="row rproduct" key={item.product._id}>
                                                <div className="col-md-4 col-sm-4">
                                                    <div className="imag-base">
                                                        <img src={item.product.imgurl} className="img-full" />
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                        <div className="product-content">
                                                            <h5  className="gray-text">{item.product.title}</h5>
                                                            <div className="barcode-display1">{item.product.barcode}</div>
                                                            <div className="sub-desc">
                                                                {item.onlineDesc}
                                                            </div>
                                                        </div>
                                                        <div className="quantity-sect">
                                                            <button onClick={() => handleDecrement(item.product._id)} className="btn btn-cjdsa btn-circle">-</button>
                                                            <div className="qtyShow">{item.quantity}</div>
                                                            <button onClick={() => handleIncrement(item.product._id)} className="btn btn-cjdsa btn-circle">+</button>
                                                        </div>

                                                        <button className="btn btn-danger btn-close-product" onClick={() => handleRemove(item.product._id)}>
                                                            <span><FaTrash /></span>
                                                        </button>
                                                        <div className="price-cart">
                                                          R {item.product.price}
                                                        </div>
                                                </div>
                                            </div>
                                            ))
                                        }
                                </div>
                                :
                                <h3 className="gray-text">No Products have been selected</h3>
                            }
                    </div>
                  </div>
                  <div className="col-md-5 mgbot10">
                        <div className="checkout">
                            <div className="checkount-title">
                                CHECK OUT
                            </div>
                            {
                                user && (
                                    <div className="content-checkout">
                                        <div className="check-sub-section check-user">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Name:</td>
                                                        <td>{user.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Surname:</td>
                                                        <td>{user.surname}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CJD Account:</td>
                                                        <td>{user.cjdAccountNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Delivery:</td>
                                                        <td>{user.deliveryAddress}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="check-sub-section check-cost">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Total:</td>
                                                        <td className="incr-total ">R {paymentCost.toFixed(2)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="mgtop30 text-center pd10">
                                                {
                                                    (paymentCost > 0) && (
                                                        <button className="btn btn-cjdsa btn-full" onClick={handleExecuteOrderingNow}>
                                                            EXECUTE ORDER
                                                        </button>
                                                    )
                                                }
                                            </div>

                                            <div className="mgtop10">
                                            {
                                                cartOrderMessage && (
                                                    <div className={"alert " + classOrderMessage}>
                                                        {cartOrderMessage}
                                                    </div>
                                                )
                                            }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                  </div>

               </div>
           </div>
   
    </div>
  )
}

export default ShoppingCart