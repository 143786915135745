//import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import MainOutlet from "./Pages/MainOutlet";
import Home from "./Pages/Home";
import AuthLogin from "./Pages/AuthLogin";
import AuthRegister from "./Pages/AuthRegister";
import Shop from "./Pages/Shop";
import ShoppingCart from "./Pages/ShoppingCart";
import AccessIsDenied from "./Pages/AccessIsDenied";
import Previous from "./Pages/Previous";

function App() {
  return (
    <>
       <Router>
        <div className="main-container">
          <Routes> 
             <Route path="/login" element={<AuthLogin />}/>  
             <Route path="/register" element={<AuthRegister />}/>
             <Route element={<MainOutlet />}>  
                <Route path="/" element={<Home />}/>  
                <Route path="/shop" element={<Shop />}/>  
                <Route path="/shopping-cart" element={<ShoppingCart />}/>  
                <Route path="/previous" element={<Previous />}/>  
              </Route>
              <Route path="*" element={<AccessIsDenied />} />
          </Routes> 
        </div>
       </Router>
       <ToastContainer />
    </>
  );
}

export default App;
