import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import * as CONSTANTS from "./../CONSTANTS";
import axios from "axios";
import HeaderLogo from './../assets/app_logo.png';
import { useDispatch } from 'react-redux';
import { logout, reset } from './../reduxAuth/authSlice';


function Home() {
 
  const [processing, setProcessing]                               = useState(false);
  const [bannerItems, setBannerItems]                             = useState();
  const [bannerMessage, setBannerMessage]                         = useState();
  const navigate                                                  = useNavigate();
  const dispatch                                                  = useDispatch();

  useEffect(() => {
    collectTheBannerItems();
  }, [])

  const collectTheBannerItems = async () => {

    try{
      setProcessing(true);
    
      const resBanners = await axios.get(CONSTANTS.API_URL + "banners/visible/list");
      console.log(resBanners);
      setBannerItems(resBanners.data);

      if(resBanners.data.length === 0){
        setBannerMessage("No Data");
      }
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  const onLogOut = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  }

  return (
    <div>
        <div className="openingbanner">
            <div className="nav-isolation">
              <Link className="navbar-brand" to="/">
                  <img src={HeaderLogo} className="logo-small home-login" alt="CJD Welcome" />
              </Link>
              <div className="side-nav-item left">
                <Link className="btn btn-cjdsa" to="/previous">
                    Previous Orders
                </Link>
                <button className="btn btn-cjdsa mgleft5" onClick={onLogOut}>
                              Logout
                  </button>
              </div>
            </div>
            <div className="container">
               <div className="seach-box-home text-center mgtop30">
                  <h2>Welcome to the CJD SA Ecommerce Application</h2>
                  <Link to="/shop" className="btn btn-cjdsa lg-butt1 mgtop30">
                    <span className="iconSearch"><FaSearch /></span>
                    <span className="txtSearchButt"> SEARCH FOR PRODUCTS</span>
                  </Link>
               </div>
              
            </div>
        </div>

        <div className="mgtop30">

              <div className="container">
                 <div className="row">

                    {
                      bannerItems && (
                        <>
                          {
                            bannerItems.map((banner, index) => {
                              var bannerClass = "";
                              if(banner.size == 0){
                                bannerClass = "col-md-4";
                              }else  if(banner.size == 1){
                                bannerClass = "col-md-6";
                              } else {
                                bannerClass = "col-md-12";
                              }

                              return (<div className={bannerClass + ""} key={index}>
                                       <Link to="/shop"
                                            state={{searcher: banner.title }}
                                          >
                                       <div className="back-response" style={{ 
                                                backgroundImage: `url("${banner.url}")` 
                                              }}>

                                          <div className="cl-title-range">
                                              {banner.title}
                                          </div>
                                        </div>
                                       </Link>
                                    </div>)
                            })
                          }
                        </>
                      )
                    }
                   {
                    bannerMessage && (<h2>{bannerMessage}</h2>)
                   }
                   {
                    processing && (
                        <div className="process-container">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="processing"/>
                        </div>
                    )
                  }
                 </div>
               </div>
        </div>
    </div>
  )
}

export default Home