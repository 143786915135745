export const API_URL = "https://cjdsa-ecom1-f46eef71866b.herokuapp.com/api/";
export const SESSION_COOKIE = "userCJDAbrahamreaiuuerXboxLIve";

//export const API_URL = "http://localhost:2299/api/"
//export const SESSION_COOKIE = "userCJDIsaacreaiuuerXboxLocal";

export const API_PICNIC = "https://picknickapi2.herokuapp.com/api/";

export const CART_COOKIE         = "cartCJDSAdata-Local";
export const SMALL_PROCESSING    = "https://cjmarketing.co/image_directory/orange_circles.gif";
export const SMALL_PROCESSINGV2  = "https://cjmarketing.co/image_directory/orangeloading2.gif";
export const TOKEN_CATALOGUE     = "9d2955a5-b0e9-4139-a88f-25692dfe6396";