import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AccessIsDenied from './AccessIsDenied';

function MainOutlet() {
  const navigate              = useNavigate();
  const {user}                = useSelector((state) => state.auth);

  useEffect(() => {

      if(!user){
          navigate("/login");
      }
  },[user, navigate])

  return (
    <div className="outer-main">
       
        <div className="main-dash-content">
          {
            user ? 
            <div className="main-content-arena">
                  <Outlet />
              </div>
              :
              <AccessIsDenied />
          }
              
          </div>
    </div>
  )
}

export default MainOutlet