import React from 'react';
import accessImage from './../assets/acces_denied.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../reduxAuth/authSlice';

function AccessIsDenied() {
    const navigate          = useNavigate();
    const dispatch          = useDispatch();

    const onLogOut = () => {

        dispatch(logout());
        dispatch(reset());
        navigate("/login");
      }
  return (
    <div className="container">
        <div className="access-container">
            <h2>Access Is Denied</h2>
            <p>Please contact administrator for enquiry.</p>
            <div className="ac-img">
            <img src={accessImage} className="logo-on-display" alt="Rescure Script" />
            </div>
            <p>Your account or pharmacy may be disabled, please contact your rescure scripts administrator to get it sorted.</p>
            <button className="btn btn-danger" onClick={onLogOut}>Logout</button>
        </div>
    </div>
  )
}

export default AccessIsDenied