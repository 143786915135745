import { createSlice } from '@reduxjs/toolkit';
import * as CONSTANTS from "../CONSTANTS";

const loadCartFromStorage = () => {
    const cartData = localStorage.getItem(CONSTANTS.CART_COOKIE);
    return cartData ? JSON.parse(cartData) : [];
};

const saveCartToStorage = (cart) => {
    localStorage.setItem(CONSTANTS.CART_COOKIE, JSON.stringify(cart));
  };
  
const clearCartAndLocalStorage = () => {
    localStorage.removeItem(CONSTANTS.CART_COOKIE); // Clear cart data from local storage
    return { items: [] }; // Return an empty cart state
};
  
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: loadCartFromStorage(),
  },
  reducers: {
    addItem(state, action) {
        const newItem = action.payload;
        const existingItem = state.items.find(item => item.product._id === newItem._id);

        if (existingItem) {
            // If item already exists, increment quantity
            existingItem.quantity += 1;
        } else {
            // If item doesn't exist, add it with quantity 1
            state.items.push({ product: newItem, quantity: 1 });
        }
        saveCartToStorage(state.items);

      },
      removeItem(state, action) {
        const idToRemove = action.payload;
        state.items = state.items.filter(item => item.product._id !== idToRemove);
        saveCartToStorage(state.items);
      },
      incrementQuantity(state, action) {
        const idToUpdate = action.payload;
        const itemToUpdate = state.items.find(item => item.product._id === idToUpdate);
        if (itemToUpdate) {
          itemToUpdate.quantity += 1;
        }
        saveCartToStorage(state.items);
      },
      decrementQuantity(state, action) {
        const idToUpdate = action.payload;
        const itemToUpdate = state.items.find(item => item.product._id === idToUpdate);
        if (itemToUpdate && itemToUpdate.quantity > 1) {
          itemToUpdate.quantity -= 1;
        }
        saveCartToStorage(state.items);
      },
      incrementProductQuantity(state, action) {
        const idToUpdate = action.payload;
        const itemToUpdate = state.items.find(item => item._id === idToUpdate);
        if (itemToUpdate) {
          // Increment product quantity
          itemToUpdate.product.quantity += 1;
        }
        saveCartToStorage(state.items);
      },
      clearCart(state) {
        return clearCartAndLocalStorage();
      },
  },
});

export const { addItem, removeItem, incrementQuantity, decrementQuantity, incrementProductQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;