import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './authStyle.css';
import { useSelector, useDispatch } from 'react-redux';
import { register, reset} from '../reduxAuth/authSlice';
import Spinner from '../Components/Others/Spinner';
import HeaderLogo from './../assets/app_logo.png';

function AuthRegister() {
  const dispatch                                       = useDispatch();
  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  const emailRef                                      = useRef();
  const nameRef                                       = useRef();
  const surnameRef                                    = useRef();
  const delivaryAddressRef                            = useRef();
  const pharmacyNameRef                               = useRef();
  const cjdAccountNumberRef                           = useRef();
  const phoneNumberRef                                 = useRef();
  const accountTypeRef                                = useRef();

  useEffect(() => {
    if(isError){
      toast.error(message)
    }

    if(isSuccess){
      console.log(message);
      toast.success(message)
      resetInputsNow();
    }

    dispatch(reset())
  },[user, isError, isSuccess, message, dispatch])

  const handleRegister = async (e) => {
    e.preventDefault();
    try{
      const userData1 = {
        "name": nameRef.current.value,
        "surname": surnameRef.current.value,
        "email": emailRef.current.value,
        "cellNumber": phoneNumberRef.current.value,
        "cjdAccountNumber": cjdAccountNumberRef.current.value,
        "deliveryAddress" : delivaryAddressRef.current.value,
        "pharmacyName": pharmacyNameRef.current.value,
        "accountType" : accountTypeRef.current.value
      }

      dispatch(register(userData1));
    }catch(err){
      console.log(err);
    }
      
  }

  const resetInputsNow = () => {
    emailRef.current.value                = "";
    surnameRef.current.value              = "";
    nameRef.current.value                 = "";
    pharmacyNameRef.current.value          = "";  
    cjdAccountNumberRef.current.value                = "";
  }

  if (isLoading) {
    return  <Spinner />
  }

  return (
        <div className="full-login bckcjd2">
        <div className="form-block-outer reg-wider">
            <div className="frm-area">
            <div className="text-center pd10">
                <img src={HeaderLogo} className="logo-one" alt="CJD Welcome" />
                </div>
                <h2 className="title-login">Register</h2>
                    <form encType="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group frg">
                              <div className="text-form">CJD Account Number*: </div>
                              <input type="text" className="form-control wide100" ref={cjdAccountNumberRef} placeholder="Enter The CJD Account Number:" required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group frg">
                            <div className="text-form">Email*: </div>
                            <input type="email" className="form-control wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group frg">
                          <div className="text-form">Name*: </div>
                              <input type="text" className="form-control wide100" ref={nameRef}  placeholder="Enter Name" required/>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group frg">
                            <div className="text-form">Surname*: </div>
                            <input type="text" className="form-control wide100"  ref={surnameRef} placeholder="Enter Surname" required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group frg">
                            <div className="text-form">Pharmacy Name*: </div>
                            <input type="text" className="form-control wide100" ref={pharmacyNameRef}  placeholder="Enter Pharmacy Name" required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group frg">
                            <div className="text-form">Phone Number*: </div>
                            <input type="text" className="form-control wide100" ref={phoneNumberRef}  placeholder="Enter Phone Number" required/>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group frg">
                            <div className="text-form">Account Type*: </div>
                            <select className="form-control wide100" ref={accountTypeRef}>
                              <option value="dca">Type DC A</option>
                              <option value="dcb">Type DC B</option>
                              <option value="dcc">Type DC C</option>
                            </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group frg">
                            <div className="text-form">Deliver Address*: </div>
                            <input type="text" className="form-control wide100" ref={delivaryAddressRef}  placeholder="Enter Delivary Address" required/>
                        </div>                       
                      </div>                      
                    </div>
                   
                    <div className="form-group mgtop20">
                      <button className="btn btn-cjdsa btn-full ct" onClick={handleRegister}  disabled={isLoading} >Register</button>
                    </div>
                    <div className="form-group">
                        <Link to="/login">Login</Link>
                    </div>
                </form>                
            </div>
        </div>
    </div>
  )
}

export default AuthRegister