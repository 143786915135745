import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from "../../CONSTANTS";
import HeaderLogo from './../../assets/app_logo.png';
import './topnavbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from '../../reduxAuth/authSlice';
import { FaCartPlus } from 'react-icons/fa'

function Tobnavbar({ currentSearched, prodTotal, totalQuantity}) {

  const navigate                                = useNavigate();
  const dispatch                                = useDispatch();

  const {user}                                  = useSelector((state) => state.auth);

  const onLogOut = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  }

  return (
       <nav className="navbar navbar-expand-md navbar-dark fixed-top">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
                <img src={HeaderLogo} className="logo-small" alt="CJD Welcome - Ecommerce" />
            </Link>
          
            {
              user && (
                <div className="searchname">
                    <span className="nm-labelings names1">{user.name}</span>  <span className="nm-labelings names2">{user.surname} </span>
                </div>
              )
            }
            <div className="topnav-prod-total">
                <span>total</span><span className="total-Products"> {prodTotal}</span>
            </div>
            <div className="nav-search">
              {currentSearched}    
            </div>
            <div className="topnav-cart">
                <Link to="/shopping-cart">
                  <div className="cart-main">
                    <span><FaCartPlus /></span>
                    <span className="countTop">{totalQuantity}</span>
                  </div>
                </Link>
            </div>
            <div className="k-logout-width">
                <button className="btn btn-cjdsa" onClick={onLogOut}>
                          Logout
              </button>
            </div>
          </div>
        </nav>
  )
}

export default Tobnavbar