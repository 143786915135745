import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './authStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from '../reduxAuth/authSlice';
import Spinner from '../Components/Others/Spinner';
import WelcomImg from './../assets/welcome.png';
import HeaderLogo from './../assets/app_logo.png';

function AuthLogin() {
    const emailRef                                           = useRef();
    const cjdAccountRef                                        = useRef();
    const navigate                                           = useNavigate();
    const dispatch                                           = useDispatch();
    
    const {user, isLoading, isError, isSuccess, message}     = useSelector((state) => state.auth);


    useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
            navigate('/');
        }

        dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])
    
    const handleLogin = async (e) => {
        e.preventDefault();
 
        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){
            const userData = {
                "email": emailRef.current.value,
                "cjdAccountNumber": cjdAccountRef.current.value
              }        
              
              dispatch(login(userData));
        }else {
            toast.error("Email not valid");
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }
  return (
    <div className="full-login bckcjd1">
        <div className="form-block-outer">
            <div className="row frm-area">
                <div className="col-md-6">
                    <div className="text-center pd10">
                    <img src={HeaderLogo} className="logo-one" alt="CJD Welcome" />
                    </div>
                    <h2 className="title-login">Login</h2>
                    <form encType="multipart/form-data">
                        <div className="form-group frg">
                            <div className="text-form">Email: </div>
                            <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                        </div>
                        <div className="form-group frg">
                            <div className="text-form">CJD Account: </div>
                            <input type="text" className="form-control ct-content wide100"  ref={cjdAccountRef} placeholder="Enter CJD Account Number" required/>
                        </div>
                        <div className="form-group frg">
                        <button className="btn btn-cjdsa btn-full ct" onClick={handleLogin}  disabled={isLoading} >Login</button>
                        </div>
                        <div className=" frg">
                            <Link to="/register">Register</Link>
                        </div>
                        
                    </form>   
                </div>
                <div className="col-md-6">
                <img src={WelcomImg} className="logo-two" alt="CJD Welcome" />
                </div>             
            </div>
        </div>
    </div>
  )
}

export default AuthLogin