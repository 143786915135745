import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import HeaderLogo from './../assets/app_logo.png';
import { useSelector } from 'react-redux';
import * as CONSTANTS from "./../CONSTANTS";
import axios from 'axios';

function Previous() {
    const {user}                                             = useSelector((state) => state.auth);
    const [previousOrders, setPreviousOrders]                = useState([]);

    useEffect(() => {
        collectPreviousOrders();
    },[]);

    const collectPreviousOrders = async () => {
        try{

            const resOrders = await axios.get(CONSTANTS.API_URL + 'orders/single/cjdaccount/' + user.cjdAccountNumber, {
                headers: {
                    token: "Bearer " + user.accessToken
                }
            });

            setPreviousOrders(resOrders.data)
            //console.log(user);
        }catch(err){
            console.log(err);
        }
    }
  return (
    <div>
        <div className="openingbanner">
            <div className="nav-isolation">
              <Link className="navbar-brand" to="/">
                  <img src={HeaderLogo} className="logo-small home-login" alt="CJD Welcome" />
              </Link>
              <div className="side-nav-item left">
            
              </div>
            </div>
          
       
        </div>
        <div className="mgtop30">
               <div className="container">
                 <h4 className="page-title-base">Previous</h4>

                 <div className="">
                    {
                        previousOrders && (
                            <div className="product-list prev-list">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#Order CODE</th>
                                            <th>Barcode</th>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Price</th>
                                            <th>QTY</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            previousOrders.map((product, index) => {
                                                return (<tr key={index}>
                                                    <td>{product.ordercode}</td>
                                                    <td>{product.barcode}</td>
                                                    <td>{product.title}</td>
                                                    <td>{product.orderstatus}</td>
                                                    <td>{product.price}</td>
                                                    <td>{product.qty}</td>
                                                    <td>{new Date(product.createdAt).toLocaleDateString(undefined, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        })}</td>
                                                </tr>)
                                            })
                                        }

                                    </tbody>                                
                                </table>
                            </div>
                        )
                    }
                 </div>
               </div>
        </div>
    </div>
  )
}

export default Previous